import React from 'react'

function WebSlide({image}) {
  return (
    <div className='slide'>
        <img src={image} alt="" />
    </div>
  )
}

export default WebSlide